import React from "react";
import data from "../data.js";
import MaterialIcon from '@material/react-material-icon';

export default () => (
  <>
    <div className="index-features-user" style={{ backgroundColor: '#f5f5f5' }} id="about_id">
      <h4 className="text-center">{data.about.title}</h4>
      <section >
        <h4 style={{ color: '#000000' }}>Experience you can count on</h4>
        <p>
          After spending many years with direct carrier wireless business sales, our founder decided to make the switch to business telecom services. Maintaining a customer-centric mindset, he founded Wireless Business Consultants in 2009 with the goal of providing a solutions-based consultant approach to wireless sales, where the customer is always first.
        </p>
        <p>
          Our mission is to simplify the complex world of wireless for businesses nationwide. To achieve this goal, we consult with businesses one on one to understand their unique wireless needs. Then, we provide the mobile devices and mobility management solutions necessary to improve business operations, efficiency, and profitability.
        </p>
        <p>
          Our exceptional mobile device management solutions and business communication services propelled us to the top. As an Authorized Business Dealer in the country, we focus on solutions - not sales. Our in-house customer support team has over 80 years of combined experience, which means we can handle all your wireless needs and provide the best service possible. Through our knowledge of our robust product portfolio, we have helped increase productivity by more than 10 million dollars.
        </p>
      </section>
      <section >
        <h4 style={{ color: '#000000' }}>Our team</h4>
        <p>
          The team has a combined 137 years of wireless experience. This knowledge allows our team to understand our business partners' wide range of needs and obstacles.
          Through our expertise and our robust product portfolio, we have helped increase productivity by more than 10 million dollars.
        </p>
        <br />
        <br />
      </section>
    </div>
  </>
);
