import React, { useState, useRef, useEffect } from "react";
import { Form, Col, Button } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";

import * as contactApi from "../services/contactApi";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [submitIsDisabled, setSubmitIsDisabled] = useState(true);
  const [captchaValue, setCaptchaValue] = useState(null);
  const capchaRef = useRef("test");

  const handleFormChange = (event, type) => {
    type(event.target.value);
    verifyForm();
  };

  useEffect(() => {
    verifyForm();
  }, [captchaValue])

  const verifyForm = () => {
    let validName = name != "" ? true : false;
    let validMessage = message != "" ? true : false;
    let validEmail = email.includes("@") && email.includes(".");

    if (validEmail && validMessage && validName && captchaValue) {

      setSubmitIsDisabled(false);
    }
  }

  const handleSubmit = () => {
    verifiedSubmitForm();
  };

  const verifiedSubmitForm = async () => {
    // send api call with the form now for the email.
    // make fetch call
    let temp = {
      name, email, message
    };
    await contactApi.sendContactForm(temp);
  };

  const handleCaptcha = captchaVal => {
    setCaptchaValue(captchaVal);
  };

  return (
    <div className="spacial-features customFadeInUp" id="contact_id" style={{ paddingTop: 100 }}>
      <h4 className="title text-center">Contact Us</h4>
      <section className="container">
        <Form>
          <Form.Group controlId="contact_id_email">
            <Form.Label>
              Email Address
            </Form.Label>
            <Form.Control
              type="email"
              placeholder="name@email.com"
              value={email}
              onChange={e => handleFormChange(e, setEmail)}
            />
          </Form.Group>

          <Form.Group controlId="contact_id_name">
            <Form.Label>
              Name
            </Form.Label>

            <Form.Control
              type="name"
              placeholder="full name"
              value={name}
              onChange={e => handleFormChange(e, setName)}
            />

          </Form.Group>
          <Form.Group controlId="contact_id_message">
            <Form.Label>
              Message
            </Form.Label>
            <Form.Control
              as="textarea"
              row="3"
              placeholder="enter your message here"
              value={message}
              onChange={e => handleFormChange(e, setMessage)}
            />
          </Form.Group>
          <Form.Row className="justify-content-md-center" style={{ paddingBottom: 30 }}>
            <ReCAPTCHA
              ref={capchaRef}
              sitekey="6LeYoLYUAAAAAGJxTOuLd5UKJJ8jHLrofvBXdEpW"
              onChange={handleCaptcha}
            />
          </Form.Row>

          <Form.Row className="justify-content-md-center">
            <Form.Group sm="2">
              <Button type="submit" onClick={handleSubmit} disabled={submitIsDisabled}>
                Submit
              </Button>
            </Form.Group>
          </Form.Row>




        </Form>
      </section>
      <div className="agency-start-city-bg">
      </div>
    </div>
  );
};

export default Contact;
