import React from 'react';
import './App.css';
import './css/theme.css';
import Main from './Main';
import { BrowserRouter as Router, Route } from "react-router-dom";

function App() {
  return (
    <>
      <Main />
      <Router>
        <Route path="/" onEnter={() => { document.getElementById("home_id").scrollIntoView(); }} />
        <Route path="/services" onEnter={() => { document.getElementById("services_id").scrollIntoView(); }} />
        <Route path="/solutions" onEnter={() => { document.getElementById("solutions_id").scrollIntoView(); }} />
        <Route path="/about" onEnter={() => { document.getElementById("about_id").scrollIntoView(); }} />
        {/* <Route path="/partners" onEnter={() => { document.getElementById("partners_id").scrollIntoView() }} /> */}
        <Route path="/partners" />
        <Route path="/contact" onEnter={() => { document.getElementById("contact_id").scrollIntoView(); }} />
      </Router>
    </>
  );
}

export default App;
