
const apiRoot = 'https://webapi.wbcdeals.com/api';
//const apiRoot = 'http://localhost:61677/api';


export function sendContactForm(e) {

    let url = `${apiRoot}/contact/SendContactForm`;

    return new Promise((resolve, reject) => {
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },

            body: JSON.stringify(e)
        })
            .then(response => {
                // if (!response.ok) {
                //     var err = httpErrorFromStatus(response.status);
                //     return err;
                // }

                const contentType = response.headers.get('content-type');
                return contentType && response.json();
            })
            .then(json => {
                resolve(json);
            })
            .catch(error => {
                reject(error);
            });
    });
}

