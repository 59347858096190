import React from "react";
import data from "../data.js";

export default () => (
  <div className="spacial-features customFadeInUp" style={{ padding: 100 }} id="masterAgent_id">
    <section className="container">
      <h4 className="title text-center">Exclusive Master Agent</h4>
      <div className="container" style={{ display: 'flex', flex: 1, flexDirection: "column" }} >
        <div className="text-center">
          <p>Great Partnership Opportunities</p>
          <p>We are currently looking for sub-agents thought out the United States. Our sub-agents have access to a full solutions portfolio, including in-house solutions and IoT, along with our curated business partner solutions.
            </p>

        </div>
      </div>
    </section>
  </div>

);
