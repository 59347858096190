export default {
    "home": {
        "title": "Wireless business solutions to help you grow.",
        "body": "As a premier Authorized Business Dealer, we use a solutions-based approach to determine your wireless needs and provide mobility solutions and management services that impact your business. <br />With locations in Houston, Texas, as well as Tampa and Orlando, Florida, we work with businesses of all sizes to help simplify the complex world of wireless. Wireless Business Consultants is proud to have earned Top Business Partner status in 2016, 2017 and 2018.",
        "tabs": [
            {
                "title": "Solutions",
                "body": "Save time, money and increase productivity with business solutions.",
                image: "../resources/solution2.jpg",
                icon: "signal_cellular_4_bar"
            },
            {
                "title": "Plans",
                "body": "Wireless products and mobile plans that grow with your business with unlimited data, talk and text, there's nothing holding you back.",
                image: "../resources/plan.jpg",
                icon: "show_chart"
            },
            {
                "title": "Devices",
                "body": "Maximize employee collaboration and productivity while conducting business anywhere with mobile devices.",
                image: "../resources/devices.jpg",
                icon: "devices"
            }
        ]
    },
    "services": {
        "title": "Solutions & Services",
        "body": null,
        "tabs": [
            {
                "title": "Business Voice",
                "body": "Expand your business’s capabilities with Business Voice Solutions. Voice Solutions work wherever your employees are by creating connections that can increase productivity and reduce travel costs."
            },
            {
                "title": "Business Mobility",
                "body": "With the growth of the mobile workforce, employees are no longer limited to an office, desk, or computer. From outside sales, remote workers, and field-based employees, mobile device management ensures that your employees can interact easily with customers and each other."
            },
            {
                "title": "Enterprise Mobility Management",
                "body": "Mobile device management made easy. Keep your business protected with the ability to manage applications, content, and more on your mobile devices."
            },
            {
                "title": "Enhanced Push to Talk",
                "body": "Ensure that employees can communicate quickly, reliably, and securely from any location."
            },
            {
                "title": "Vehicle Solutions",
                "body": "Improve accountability, efficiency, and driver safety with real-time information on your vehicles and drivers."
            },
            {
                "title": "Workforce Productivity",
                "body": "Increase productivity with mobile forms. Collect, secure, and route data all from a mobile device."
            }
        ]
    },
    "about": {
        "title": "About Us",
        "body": [{
            "title": "Experience You Can Count On",
            "body": "After spending 12 years in direct wireless sales, Jason Kimbler decided to make the switch to business telecom services. Maintaining a customer centric mindset, he founded Wireless Business Consultants in 2009 with the goal of providing a solutions-based consultant approach to wireless sales, where the customer is always first. Our mission is to simplify the complex world of wireless for businesses nationwide. To achieve this goal, we consult with businesses one on one to understand their unique wireless needs. Then, we provide the mobile devices and mobility management solutions necessary to improve business operations, efficiency and profitability.            Our exceptional mobile device management solutions and business communication services propelled us to the top. As an Authorized Business Dealer in the country, we focus on solutions - not sales. Our in-house customer support team has over 80 years of combined experience, which means we can handle all your wireless needs and provide the best service possible. Through our knowledge of our robust product portfolio, we have helped increase productivity by more than 10 million dollars."
        }, {
            "title": "Our Team",
            "body": "The team has a combined 137 years of wireless experience. This knowledge allows our team to understand our business partners’ wide range of needs and obstacles. Through our knowledge and our robust product portfolio we have helped increase productivity by more than 10 million dollars."
        }],
        "tabs": [
            {
                "title": "Customer Centric",
                "body": "We strive to provide a positive customer experience before, during and after a sale to every customer.",
                icon: 'how_to_reg'
            },
            {
                "title": "Critical Thinking",
                "body": " Our team will skillfully analyze and assess each customer’s needs to determine the best solutions for their business problems.",
                url: 'https://cdn.pixabay.com/photo/2016/02/19/11/23/women-1209678_1280.jpg',
                icon: "people_outline"
            },
            {
                "title": "Problem Solving",
                "body": "Our goal is to solve all problems efficiently, whether simple or complex, to reach a successful outcome.",
                icon: "memory"
            },
            {
                "title": "Integrity",
                "body": "We believe in being honest and forthright with our customers and our employees.",
                icon: "sentiment_very_satisfied"
            }
        ]
    },
    "solutions": {
        "title": "Solutions",
        "tabs": [
            {
                "title": "Corrata",
                "url": "https://corrata.com/",
                "image": "https://corrata.com/wp-content/themes/project-wp-theme/img/logo--light.png"
            },
            {
                "title": "Mako Networks",
                "url": "https://makonetworks.com/",
                "image": "https://makonetworks.com/wp-content/themes/mako-30/images/header-mako@2x.png"
            },
            {
                "title": "Zenduit",
                "url": "https://zenduit.com/",
                "image": "https://elioplus.com/Images/Logos/b84519c1-fcae-4fa0-ab68-929de902377a/zenduit-logo-retina_22_10_2018_12345.png"
            },
            {
                "title": "TextP2P",
                "url": "https://textp2p.com/",
                "image": "./solutions/textp2p.png"
            },
            {
                "title": "Truce Software",
                "url": "https://trucesoftware.com/reconfiguring-business-operations-for-the-new-normal",
                "image": "./solutions/truce.png"
            },
            {
                "title": "Trumpia",
                "url": "https://trumpia.com/",
                "image": "./solutions/trumpia.png"
            },

            {
                "title": "Ellenex",
                "url": "https://ellenex.com/",
                "image": "./solutions/ellenex.png"
            },
            {
                "title": "J & M Security Solutions",
                "url": "https://google.com/",
                "image": "./solutions/JMSS.png"
            },
            {
                "title": "Cellairis",
                "url": "https://cellairis.com/busines",
                "image": "./solutions/cellairis.png"
            },
            {
                "title": "Route4me",
                "url": "https://route4me.com/",
                "image": "./solutions/route4me.png"
            },
            {
                "title": "Lynx BuyBack",
                "url": "https://lynxbuyback.com/",
                "image": "./solutions/lynx.png"
            },
            {
                "title": "Zunch labs",
                "url": "https://google.com/",
                "image": "./solutions/ZunchLabs.png"
            },
            {
                "title": "Wherible IoT",
                "url": "https://AAAAAA.com/",
                "image": "./solutions/WheribleIoT.png"
            },
            {
                "title": "igen",
                "url": "https://AAAAAA.com/",
                "image": "./solutions/igen.png"
            },
            {
                "title": "Medallion",
                "url": "https://AAAAAA.com/",
                "image": "./solutions/medallion.png"
            },
            {
                "title": "CUTrak",
                "url": "https://AAAAAA.com/",
                "image": "./solutions/cutrak.png"
            },
            {
                "title": "NIMBO",
                "url": "https://AAAAAA.com/",
                "image": "./solutions/nimbo.png"
            },
            {
                "title": "Newgate Security",
                "url": "https://AAAAAA.com/",
                "image": "./solutions/Newgate.png"
            },
            {
                "title": "Transportant",
                "url": "https://AAAAAA.com/",
                "image": "./solutions/Transportant.png"
            },

            {
                "title": "The Mobility Marketplace",
                "url": "https://AAAAAA.com/",
                "image": "./solutions/mobilityMarketplace.png"
            },
            {
                "title": "Infinite Peripherals",
                "url": "https://AAAAAA.com/",
                "image": "./solutions/infinite.jpg"
            },

            {
                "title": "Metropolitan Sales",
                "url": "https://AAAAAA.com/",
                "image": "./solutions/metropolitan.png"
            },
            {
                "title": "Satellite Phone ",
                "url": "https://AAAAAA.com/",
                "image": "./solutions/satellite.png"
            },
            {
                "title": "Auditmacs",
                "url": "https://AAAAAA.com/",
                "image": "./solutions/auditmacs.png"
            },
            {
                "title": "Cradlepoint",
                "url": "https://AAAAAA.com/",
                "image": "./solutions/cradlepoint.png"
            },
            {
                "title": "Vast",
                "url": "https://AAAAAA.com/",
                "image": "./solutions/vast.png"
            },
            {
                "title": "ReadyNet",
                "url": "https://AAAAAA.com/",
                "image": "./solutions/readynet.png"
            },
            {
                "title": "ProClip",
                "url": "https://AAAAAA.com/",
                "image": "./solutions/proclip.png"
            },
            {
                "title": "ILS",
                "url": "https://AAAAAA.com/",
                "image": "./solutions/ils.png"
            },
            {
                "title": "Zotera",
                "url": "https://AAAAAA.com/",
                "image": "./solutions/zotera.png"
            },
            {
                "title": "Midwest",
                "url": "https://AAAAAA.com/",
                "image": "./solutions/midwest.jpg"
            },
            {
                "title": "Midwest",
                "url": "https://AAAAAA.com/",
                "image": "./solutions/avlLabs.png"
            },
            {
                "title": "Midwest",
                "url": "https://AAAAAA.com/",
                "image": "./solutions/EquipmentSourcingSpecialists.png"
            },
            {
                "title": "Midwest",
                "url": "https://AAAAAA.com/",
                "image": "./solutions/CBB.png"
            },
            {
                "title": "Midwest",
                "url": "https://AAAAAA.com/",
                "image": "./solutions/encorenetworks.png"
            },
            {
                "title": "Midwest",
                "url": "https://AAAAAA.com/",
                "image": "./solutions/uplevel.png"
            },
            {
                "title": "Midwest",
                "url": "https://AAAAAA.com/",
                "image": "./solutions/sierraWireless.png"
            },
            {
                "title": "Midwest",
                "url": "https://AAAAAA.com/",
                "image": "./solutions/ReadyWireless.png"
            },
            {
                "title": "Midwest",
                "url": "https://AAAAAA.com/",
                "image": "./solutions/Peplink.png"
            },
            {
                "title": "Midwest",
                "url": "https://AAAAAA.com/",
                "image": "./solutions/TheFirm.png"
            },
            {
                "title": "Midwest",
                "url": "https://AAAAAA.com/",
                "image": "./solutions/CyberReef.png"
            },
            {
                "title": "Midwest",
                "url": "https://AAAAAA.com/",
                "image": "./solutions/evolve.png"
            },
            {
                "title": "Midwest",
                "url": "https://AAAAAA.com/",
                "image": "./solutions/hdfleet.png"
            },


        ]
    },
    // "partner": {
    //     "Main": "Sprint Exclusive Master Agent",
    //     "title": "Great Partnership Opportunities",
    //     "body": "We are currently looking for sub-agents thought out the United States. Our sub-agents have access to a full solutions portfolio, including in-house solutions and IOT, along with our curated business partner solutions.",
    // }
}