import React, { Component } from 'react'
import About from './pages/about'
import Services from './pages/services'
import Index from './pages/home'
import Partners from './pages/partners'
import Header from './components/header'
import Footer from './components/footer'
import Solutions from './pages/solutions'
import Contact from './pages/contact'

export default class Main extends Component {
    render() {
        return (
            <>
                <Header />
                <Index />
                <Services />
                <Solutions />
                <About />
                <Partners />
                <Contact />
                <Footer />
            </>
        )
    }
}
