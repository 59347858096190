import React from "react";
import data from "../data.js";

export default () => (
  <>
    <div className="index-app-hero" id="home_id">
      <div className="container">
        <h2 className="customFadeInUp">Solutions to help you grow.</h2>
        <div className="row">
          <div className="col-md-9 feature">
            <p className="customFadeInUp">

              Premier Master Agent that focuses on a solutions-based approach to determine mobility needs. Impacting business outcomes by providing mobility solutions and managed services. We work with businesses of all sizes to help simplify the complex world of mobility.
              <br />Wireless Business Consultants is proud to have earned Top Business Partner for the past 6 years.</p>
            <br />
            <img src="images/att/partner.png" width="400" />

          </div>
          <div className="col-md-3 feature">
            <img src="images/att/built-with-atat.png" />
          </div>
        </div>
      </div>
    </div>

    {/* <div className="pricing-features">
      <div className="container">
        <section className="features">
          <div className="row">
            <div className="col-md-6 feature">
              <img src="images/att/att.png" />

            </div>
            <div className="col-md-6 feature">
              <img src="images/att/built-with-atat.png" />

            </div>
          </div>

        </section>
      </div>
    </div> */}



    <div className="pricing-features">
      <div className="container">
        <section className="features">
          <div className="row">
            <div className="col-md-6 feature">
              <img src="images/icons/outline-color-user.png" className="icon" />
              <section>
                <h4>
                  Customer Centric
                </h4>
                <p>
                  We strive to provide a positive customer experience before, during and after a sale to every customer.
                </p>
              </section>
            </div>
            <div className="col-md-6 feature">
              <img src="images/icons/outline-color-settings.png" className="icon" />
              <section>
                <h4>
                  Critical Thinking
                </h4>
                <p>
                  Our team will skillfully analyze and assess each customer’s needs to determine the best solutions for their business problems.
                </p>
              </section>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 feature">
              <img src="images/icons/outline-color-random.png" className="icon" />
              <section>
                <h4>
                  Problem Solving
                </h4>
                <p>
                  Our goal is to solve all problems efficiently, whether simple or complex, to reach a successful outcome.
                </p>
              </section>
            </div>
            <div className="col-md-6 feature">
              <img src="images/icons/features-icon-ok.png" className="icon" />
              <section>
                <h4>
                  Integrity
                </h4>
                <p>
                  We believe in being honest and forthright with our customers and our employees.
                </p>
              </section>
            </div>
          </div>
        </section>
      </div>
    </div>

    {/* <div className="index-features-files">
      <div className="container2">
        <div className="container1">
          <div className="col col-left">
            <div className="features">
              {data.home.tabs.map((tab, index) => (
                <div className="feature cleartext" style={{ display: 'flex', flexDirection: 'row' }} key={index}>
                  {tab.icon &&
                    <MaterialIcon className="image" icon={tab.icon} />
                  }
                  <div className="info">
                    <strong>{tab.title}</strong>
                    <p>{tab.body}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {deviceMobile ? null :
            <div className="col col-right">
              <div className="info">
                <img src={plans} />
              </div>

            </div>}
        </div>
      </div>
    </div> */}
  </>
);
