import React from "react";
import data from "../data";


export default () => (
  <div id="solutions_id">
    <div className="spacial-features" style={{ paddingTop: 50, paddingBottom: 100 }}>
      <div className="customers-grid">
        <div className="container">
          <h4 className="title text-center" style={{ paddingBottom: 40 }}>Solution Partners</h4>
          <div className="row">
            {data.solutions.tabs.map((tab, index) => (
              <div key={tab.title} className="col-md-3 p-5" style={{ textAlign: '-webkit-center' }}>
                {/* <a href={tab.url} target="_blank"> */}
                <img src={tab.image} className="img-fluid d-block" style={{ height: 50 }} />
                {/* <h6 style={{ marginTop: 5 }}>{tab.title}</h6> */}
                {/* </a> */}
                {/* <p>
                Providing your organization with the best protection against cyber threats and the greatest control over mobile device usage.
              </p> 
                <a href="https://corrata.com/" target="_blank" className="read-story">Learn more</a>*/}
              </div>
            ))}
          </div>

        </div>
      </div>
    </div>
  </div>
);