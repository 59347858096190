import React, { Component } from 'react';
import { NavLink } from "react-router-dom";

export default class footer extends Component {
    render() {
        return (

            <div className="footer">
                <div className="container">

                    <div className="row">
                        <div className="col-md-4">
                            <ul className="menu">
                                <li>
                                    <NavLink to="/" onClick={() => document.getElementById("home_id").scrollIntoView({ behavior: "smooth" })}>Home</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/" onClick={() => document.getElementById("services_id").scrollIntoView({ behavior: "smooth" })}>Services</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/" onClick={() => document.getElementById("solutions_id").scrollIntoView({ behavior: "smooth" })}>Solution Partners</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/" onClick={() => document.getElementById("about_id").scrollIntoView({ behavior: "smooth" })}>About</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/" onClick={() => document.getElementById("masterAgent_id").scrollIntoView({ behavior: "smooth" })}>Master Agent</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/" onClick={() => document.getElementById("contact_id").scrollIntoView({ behavior: "smooth" })}>Contact</NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-4 newsletter">
                            <p>
                                1463 Oakfield Drive<br />
                                Brandon, FL 33511
                            </p>
                        </div>
                        <div className="col-md-4 newsletter">
                            <p>Call us today!<br />(813) 495-8140</p>
                            <a href="mailto:wbcoperations@wbc-wireless.com" className="legal">wbcoperations@wbc-wireless.com</a>
                            <ul className="menu">
                                <li>
                                    <a href="https://www.facebook.com/Wirelessbusinessconsultants/?tn-str=k*F" target="_blank">
                                        <i className="fa fa-facebook"></i>
                                        Facebook
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="container">
                        <div className="bottom newsletter">
                            <p className="text-center">COPYRIGHT © 2020 WIRELESS BUSINESS CONSULTANTS - ALL RIGHTS RESERVED</p>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
