import React from "react";
import data from "../data";
import "../css/styles.css";


export default () => (
  <>
    <div className="index-features-files index-app clearfix" id="services_id">
      <h4 className="text-center">Solutions & Services</h4>
      <br />
      <div className="container2" style={{ backgroundColor: '#ffffff' }}>
        <div className="container1" >
          <div className="col col-left">

            <div className="features" >
              <div className="feature clearfix">
                <div className="image white">
                  <img src="images/icons/features-icon-upload.png" />
                </div>
                <div className="info">
                  <strong>Enterprise Mobility Management</strong>
                  <p>
                    Mobile device management made easy. Keep your business protected with the ability to manage applications, content, and more on your mobile devices.
                  </p>
                </div>
              </div>
              <div className="feature clearfix">
                <div className="image">
                  <img src="images/icons/features-icon-earth.png" />
                </div>
                <div className="info">
                  <strong>Enhanced Push to Talk</strong>
                  <p>Ensure that employees can communicate quickly, reliably, and securely from any location.</p>
                </div>
              </div>
              <div className="feature clearfix">
                <div className="image">
                  <img src="images/icons/features-icon-repeat.png" />
                </div>
                <div className="info">
                  <strong>Vehicle Solutions</strong>
                  <p>Improve accountability, efficiency, and driver safety with real-time information on your vehicles and drivers.</p>
                </div>
              </div>
            </div>

          </div>

          <div className="col col-right" >
            <div className="features" >
              <div className="feature">
                <div className="image">
                  <img src="images/icons/features-icon-upload.png" />
                </div>
                <div className="info">
                  <strong>Business Voice</strong>
                  <p>
                    Expand your business’s capabilities with Business Voice Solutions. Voice Solutions work wherever your employees are by creating connections that can increase productivity and reduce travel costs.
                  </p>
                </div>
              </div>
              <div className="feature clearfix">
                <div className="image">
                  <img src="images/icons/features-icon-upload.png" />
                </div>
                <div className="info">
                  <strong>Business Mobility</strong>
                  <p>
                    With the growth of the mobile workforce, employees are no longer limited to an office, desk, or computer. From outside sales, remote workers, and field-based employees, mobile device management ensures that your employees can interact easily with customers and each other.
                  </p>
                </div>
              </div>
              <div className="feature clearfix">
                <div className="image">
                  <img src="images/icons/features-icon-world.png" />
                </div>
                <div className="info">
                  <strong>Workforce Productivity</strong>
                  <p>Increase productivity with mobile forms. Collect, secure, and route data all from a mobile device.</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>


  </>

);
